import { DSSocialIcon } from 'shared-definitions/types'

export function socialShare(social: DSSocialIcon, link: string, text: string): string | null {
  const map: Record<DSSocialIcon, null | ((link: string, title: string) => string)> = {
    facebook: l => `https://www.facebook.com/sharer.php?u=${encodeURI(l)}`,
    instagram: null,
    tiktok: null,
    email: null,
    play_store: null,
    youtube: null,
    podcast: null,
    snapchat: null,
    rss: null,
    twitter: (l, t) => `https://twitter.com/intent/tweet?url=${encodeURI(l)}&text=${encodeURI(t)}`,
  }
  const mapper = map[social]
  if (!mapper) {
    return null
  }
  return mapper(link, text)
}
